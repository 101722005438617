import Router from "next/router";
import NProgress from "nprogress";
import { mutate } from "swr";
import { DefaultSeo } from "next-seo";
import "../styles/globals.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

Router.onRouteChangeStart = (url) => {
  if (url !== window.location.pathname) {
    window.routeTimeout = setTimeout(() => (window.location = url), 0.5);
    // NProgress.start();
  }
};
Router.onRouteChangeComplete = (url) => {
  clearTimeout(window.routeTimeout);
  // NProgress.done();
};

const cfUrl = process.env.API_CF_URL;
 const latestTransmissionCountPH = `${cfUrl}/latestTransmissionCount`;
  mutate(latestTransmissionCountPH, fetch(latestTransmissionCountPH, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data: { locationSlug: 'philippines' }})
  }).then(async (res) => {
    const x = await res.result;
    return ({ ...x, source: 'prefetch' });
  }).catch(() => null));

  const latestTransmissionCountOAV = `${cfUrl}/latestTransmissionCount`;
  mutate(latestTransmissionCountOAV, fetch(latestTransmissionCountOAV, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data: { locationSlug: 'oav' }})
  }).then(async (res) => {
    const x = await res.result;
    return ({ ...x, source: 'prefetch' });
  }).catch(() => null));

const PHVOTE = ({ Component, pageProps }) => {
  return (
    <>
      <DefaultSeo
        openGraph={{
          type: "website",
          site_name: "PHVOTE",
          images: [
            {
              url: "https://www.rappler.com/tachyon/2022/03/ATIN-PILIPINAS.jpg",
              width: 800,
              height: 600,
              alt: "ATIN ANG PILIPINAS",
              type: "image/jpeg",
            },
          ],
        }}
        twitter={{
          handle: "@handle",
          site: "@site",
          cardType: "summary_large_image",
        }}
      />
      <Component {...pageProps} />
    </>
  );
};

export default PHVOTE;
